// app/global-error.js
"use client";

import Link from "next/link";
import { useEffect } from "react";

export default function GlobalError({ error, reset }) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  const goHome = () => {
    if (window.location.pathname !== "/") window.location.href = "/";
    else {
      window.location.reload();
    }
  };

  return (
    <html>
      <body className="error-body">
        <div className="error-page">
          <h1>Sorry it's not you, it's us</h1>
          <p>we're working on it. Be back soon!</p>
          <button className="submit-button" onclick={() => goHome()}>
            Go back home
          </button>
        </div>
      </body>
    </html>
  );
}
